const router_modular = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/HomeView"),
  },
  {
    path: "/scenic",
    name: "scenic",
    component: () => import("@/views/HomeView"),
  },
];
export default router_modular;
